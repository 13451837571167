import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import Modal from "./website/shared/UI/Modal";

import "./index.css";

ReactDOM.render(
  <BrowserRouter
    getUserConfirmation={(message, callback) => {
      // this is the default behavior
      Modal(message, callback);
    }}
  >
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
