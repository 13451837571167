import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

// eslint-disable-next-line no-unused-vars
import localization from "./website/util/localization";

import "./App.css";

const ContainerGift = React.lazy(() => import("./gift/Container.js"));
const ContainerOffer = React.lazy(() => import("./offer-updated/Container.js"));
const ContainerSubs = React.lazy(() =>
  import("./subscription-update/Container.js")
);
const Website = React.lazy(() => import("./website/App.js"));

const ResetCode = React.lazy(() => import("./website/other/ResetPassword"));

class App extends Component {
  render() {
    let routes = (
      <Switch>
        <Route
          path="/password/reset/:id"
          render={(props) => {
            return <ResetCode {...props} />;
          }}
        />
        <Route
          path="/gift/:giftId"
          exact
          render={(props) => {
            return <ContainerGift {...props} />;
          }}
        />
        <Route
          path="/offer/:offerId"
          render={(props) => {
            return <ContainerOffer {...props} />;
          }}
        />
        <Route
          path="/subscriptions"
          exact
          render={(props) => {
            return <ContainerSubs {...props} />;
          }}
        />
        <Route
          path="/"
          render={(props) => {
            return <Website {...props} />;
          }}
        />
      </Switch>
    );

    return <Suspense fallback={() => <p>Yükleniyor...</p>}>{routes}</Suspense>;
  }
}

export default App;
