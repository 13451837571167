import { t } from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { Trans } from "react-i18next";

import classes from "./Modal.module.css";

const Modal = (message, callback) => {
  const container = document.createElement("div");
  container.setAttribute("custom-confirmation-navigation", "");
  container.setAttribute("class", classes.Container);
  document.body.appendChild(container);

  const exitPage = (callbackState) => {
    ReactDOM.unmountComponentAtNode(container);
    document.body.removeChild(container);
    callback(callbackState);
  };
  const [messageText, cancelText, confirmText] = message.split("stop");

  ReactDOM.render(
    <div
      className={classes.Modal + " text-center"}
      onClick={(e) => e.stopPropagation()}
    >
      <span className={classes.ExitIcon} onClick={() => exitPage(false)}>
        x
      </span>

      <div className={classes.Content}>
        <div className={classes.WarningIcon}>!</div>
        <p className={classes.Text}>
          <Trans i18nKey={messageText} components={{ br: <br /> }} />
        </p>
        <button className={classes.Button} onClick={() => exitPage(false)}>
          {t(cancelText)}
        </button>
        <button className={classes.Link} onClick={() => exitPage(true)}>
          {t(confirmText)}
        </button>
      </div>
    </div>,
    container
  );
};

export default Modal;
