import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const options = {
  order: ["path", "querystring", "localStorage", "navigator"],
  lookupQuerystring: "lng",
  lookupLocalStorage: "dinlebiLng",
  caches: ["localStorage"],
  lookupFromPathIndex: 0,
};

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    backend: {
      loadPath: `/api/language/{{lng}}/translation.json`,
    },
    supportedLngs: ["tr", "TR", "en", "EN", "de", "DE"],
    detection: options,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
